<template>
  <div class="course_card">
    <div class="row">
      <!-- 基本信息 -->
      <div style="width: 250px">
        <p class="title">
          {{ info.groupName }}
        </p>
        <p class="learner" v-if="learner.length">
          {{ learner }}
        </p>
        <p class="time">
          {{ info.timeRegion }}
        </p>
        <div class="progress">
          {{
            info.planRecordStatus == 3 || info.planRecordStatus == 4
              ? "原"
              : "当前"
          }}第{{ info.num }}节 | 共{{ info.total }}节
        </div>
      </div>

      <!-- 状态 -->
      <div>
        <Button
          class="confirm_btn"
          type="theme"
          v-if="info.planRecordStatus === 1"
          @click="toggleModalVisible"
        >
          确认课时
        </Button>
        <div class="status" v-else>
          <div class="text" :style="`color: ${statusTxt[1]}`">
            {{ statusTxt[0] }}
          </div>
        </div>
      </div>
    </div>
    <ConfirmBottom
      :visible="confirmModal.visible"
      confirm1Btn="未上课"
      confirm2Btn="确认上课"
      @cancel="toggleModalVisible"
      @confirm1="handleConfirm(3)"
      @confirm2="handleConfirm(2)"
    >
      <p>
        Hi~ {{ info.teacherName }}老师 <br />
        {{ info.groupName }} {{ learner.length ? "-" : "" }}
        {{ learner }}的课程上课了吗
      </p>
      <p class="tips">
        {{ info.timeRegion }}
      </p>
      <p class="tips">
        {{
          info.planRecordStatus == 3 || info.planRecordStatus == 4
            ? "原"
            : "当前"
        }}第{{ info.num }}节 | 共{{ info.total }}节
      </p>
    </ConfirmBottom>
  </div>
</template>
<script>
import Button from "../../components/Button.vue";
import ConfirmBottom from "../../components/ConfirmBottom.vue";
import { RecordStatusEnumForTeacher } from "../../../api/constant";
import { confirmClass } from "../../../api/mentor";

export default {
  name: "mentor-history-card",
  props: {
    info: {
      type: Object,
      require: true,
      default: () => {
        return {
          groupName: "",
          studentName: [],
        };
      },
    },
  },
  data() {
    return {
      confirmModal: {
        visible: false,
      },
    };
  },
  computed: {
    learner() {
      return this.info?.studentName?.join("、") ?? "";
    },
    statusTxt() {
      const { planRecordStatus } = this.info;
      /*       const color = {
        2: '#BFBFBF', // 已上课
        3: '#1677FF', // 未上课
        7: '#F52C41', // 待确认
      }[planRecordStatus] */
      return [RecordStatusEnumForTeacher[planRecordStatus], "#BFBFBF"];
    },
  },
  methods: {
    toggleModalVisible() {
      if (new Date() < new Date(this.info.startTime)) {
        this.$toast("未到确认时间～");
      } else {
        this.confirmModal.visible = !this.confirmModal.visible;
      }
    },
    async handleConfirm(state) {
      try {
        //  2: '已上课',3: '未上课',
        await confirmClass({
          recordStatus: state,
          planId: this.info.planId,
        });
        if (state === 2) {
          this.$toast("老师辛苦啦~");
        } else {
          this.$toast("感谢反馈~");
        }
        this.info.planRecordStatus = state;
        this.toggleModalVisible();
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: { Button, ConfirmBottom },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.course_card {
  width: 100%;
  min-height: 86px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: fz18px;
  box-shadow: 0 1px 4px #6666660f;

  &+.course_card {
    margin-top: 10px;
  }

  .title {
    font-size: fz16px;
    color: #444F6A;
    line-height: fz18px;
    margin: 6px 0;
    vendor(1);
  }

  .learner {
    font-size: fz14px;
    color: #10213F;
    line-height: fz14px;
    margin: 8px 0;
    vendor(1);
  }

  .progress, .time {
    font-size: fz12px;
    color: #C6C6C6;
    margin-top: 6px;
  }

  .status {
    width: 40px;
    word-break: break-all;
    margin-right: 12px;

    .text {
      font-size: fz12px;
      line-height: fz16px;
    }
  }

  .confirm_btn {
    width: 60px;
    font-size: fz12px;
  }
}
</style>